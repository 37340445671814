/* eslint-disable no-unused-vars */
import React from 'react'
import { css } from 'styled-components'

export const HeroCSS = css`
  background-color: ${props => props.theme.color.secondary};
  position: relative;
`

export const HeroImageCSS = css`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;

  & > .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`

export const HeroGradientCSS = css`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(0,0,0,0.16290266106442575) 0%, rgba(0,0,0,0.4318102240896359) 100%);
`