/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

export const StyledBrandTitle = styled.h3`
  font-weight: ${props => props.theme.font.weight.l};
  font-family: ${props => props.theme.font.family.secondary};
  color: ${props => props.theme.color.text.secondary};
  text-transform: uppercase;
  position: relative;
  letter-spacing: 1.5px;

  @media (min-width: 992px) {
    font-size: ${props => props.theme.font.size.xxxl};
  }

  @media (max-width: 991px) {
    font-size: ${props => props.theme.font.size.xxl};
  }
`

const BrandTitle = () => {
  const {
    site: {
      siteMetadata: {
        title
      }
    }
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <StyledBrandTitle>
      {title}
    </StyledBrandTitle>
  )
}

export default BrandTitle