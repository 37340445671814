/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

const StyledTitleSub = styled.h2`
  font-weight: ${props => props.theme.font.weight.s};
  font-family: ${props => props.theme.font.family.secondary};
  color: ${props => props.theme.color.text.secondary};
  text-transform: uppercase;
  position: relative;
  display: inline-block;

  &:before, &:after {
    background-color: ${props => props.theme.color.text.secondary};
    content: '';
    position: absolute;
    ${props => props.right ? 'left: 110%' : 'right: 110%'};
    width: 3000px;
    height: 2px;
  }

  &:before {
    top: 40%;
  }

  &:after {
    bottom: 40%;
  }

  @media (min-width: 992px) {
    font-size: ${props => props.theme.font.size.xxl};
  }

  @media (max-width: 991px) {
    font-size: ${props => props.theme.font.size.xl};
  }

  @media (max-width: 767px) {
    &:before, &:after {
      content: none;
    }
  }
`

const TitleSub = ({ className, children, right }) => (
  <div>
    <StyledTitleSub right={right} className={className}>
      {children}
    </StyledTitleSub>
  </div>
)

export default TitleSub