/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

// Icons
import checkmark from 'img/checkmark.svg'

const StyledContentDefault = styled.div`
  font-size: ${props => props.theme.font.size.m};
  font-weight: ${props => props.theme.font.weight.m};
  font-family: ${props => props.theme.font.family.main};
  color: ${props => props.theme.color.text.secondary};

  & h1, & h2 {
    font-size: ${props => props.theme.font.size.xm};
    font-weight: ${props => props.theme.font.weight.l};
  }

  & a {
    color: ${props => props.theme.color.text.contrast};

    &:hover {
      text-decoration: underline;
    }
  }

  & ul {
    margin: 0;
    padding: 0;

    & li {
      list-style-type: none;
      position: relative;
      padding-left: 35px;
      margin: 10px 0;

      &:before {
        position: absolute;
        left: 5px;
        top: 1px;
        content: '';
        background-image: url(${checkmark});
        background-size: 20px 20px;
        height: 20px;
        width: 20px;
        display: block;
      }
    }
  }
`

const ContentDefault = ({ className, content }) => (
  <StyledContentDefault className={className}>
    <ParseContent content={content} />
  </StyledContentDefault>
)

export default ContentDefault